<template>
  <Section>
    <Container>
      <template v-if="modalities.length > 0">
        <Checkbox
          id="selectAll"
          :modelValue="allItemsSelected"
          @update:modelValue="checkAllItems"
          :items="[{
                label: 'Sélectionner tout',
                value: true,
              }]"
        />
        <template v-for="(modality, index) in modalities" :key="index">
          <Checkbox
            :id="index.toString()"
            :modelValue="form.modalities.findIndex((m) => m.id === modality.id) !== -1"
            @update:modelValue="selectModalities(modality)"
            :items="[{
                label: modality.designation,
                value: true,
              }]"
          />
        </template>
      </template>
      <template v-else>
        Aucune modalité à afficher.
      </template>
    </Container>
  </Section>
</template>

<script>
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import Checkbox from '@/components/form/Checkbox.vue'

export default {
  name: 'ModalitiesAddSubview',
  components: {
    Checkbox,
    Container,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    // Define a validation schema
    const validationSchema = this.yup.object().shape({
    })

    // init du form
    const { errors, isSubmitting } = this.formService.initFrom(validationSchema)

    // gestion du onSubmit
    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleValues(values)
    })

    return {
      onSubmit,
      errors,
      isSubmitting,
      method: 'post',
      endpoint: null,
      essai: {},
      modalities: [],
      form: {
        modalities: [],
      },
      allItemsSelected: null,
    }
  },
  emits: ['set-step', 'set-data', 'set-route'],
  watch: {
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
    modelValue() {
      if (this.modelValue) {
        this.onSubmit()
      }
      this.$emit('update:modelValue', false)
    },
  },
  mounted() {
    if (!this.$route.name.includes('Edit')) {
      this.previousRouteName = 'essaiFixedReportTitleAdd'
    }

    this.$emit('set-route', {
      previousRouteName: this.previousRouteName,
      routeParams: {
        id: this.$route.params.id,
        rid: this.$route.params.rid,
      },
    })

    this.$emit('set-step', 1)

    this.getModalities()
  },
  methods: {
    /**
     * Get modalities from protocol
     */
    getModalities() {
      this.emitter.emit('open-loader')
      this.fetchService.get(`essai/${this.$route.params.id}`).then((essai) => {
        this.fetchService.get(`protocole/${essai.data.protocole.id}/modalite`, {
          limit: 0,
          sort: 'ordre.ASC',
        })
          .then((modalities) => {
            this.modalities = modalities.data
            this.getSelectedModalities()
            this.emitter.emit('close-loader')
          })
      })
    },
    /**
     * Get already selected modalities
     */
    getSelectedModalities() {
      this.fetchService.get(`essai/${this.$route.params.id}/rapport/${this.$route.params.rid}/modalite`, {
        limit: 0,
        sort: 'ordre.ASC',
      })
        .then((response) => {
          response.data.forEach((modality) => {
            if (modality.id !== null) {
              this.selectModalities(modality)
            }
          })
        })
    },
    /**
     * Select modalities to be processed after
     * @param modality
     */
    selectModalities(modality) {
      const index = this.form.modalities.findIndex((m) => m.id === modality.id)
      if (index > -1) {
        this.form.modalities.splice(index, 1)
      } else {
        this.form.modalities.push({
          id: modality.id,
        })
      }

      if (this.isAllItemChecked()) {
        this.allItemsSelected = true
      } else {
        this.allItemsSelected = false
      }
    },
    checkAllItems() {
      if (!this.isAllItemChecked()) {
        console.log('tous checked')
        this.form.modalities = []
        this.modalities.forEach((mo) => {
          this.selectModalities(mo)
        })
      } else {
        console.log('pas tous checker')
        this.form.modalities = []
      }
    },
    isAllItemChecked() {
      let isAllIn = true
      const modalities = []
      this.form.modalities.forEach((m) => {
        modalities.push(m.id)
      })
      this.modalities.forEach((mo) => {
        if (!modalities.includes(mo.id)) {
          isAllIn = false
        }
      })

      return isAllIn
    },
    handleValues(values) {
      this.emitter.emit('open-loader')
      console.log('Values', values)
      this.fetchService
        .put(`essai/${this.$route.params.id}/rapport/${this.$route.params.rid}/modalite`, {
          modalites: this.form.modalities,
        })
        .then(() => {
          const params = {
            id: this.$route.params.id,
            rid: this.$route.params.rid,
          }
          this.$router.push({
            name: 'essaiFixedReportRepetitionsAdd',
            params,
          })
          this.emitter.emit('close-loader')
        },
        (error) => {
          this.emitter.emit('close-loader')
          this.formService.handleApiError(error)
        })
    },
  },
}
</script>
